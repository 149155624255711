'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.home.controller:HomeCtrl

 # @description

###
class AdminHomeCtrl
  ### @ngInject ###
  constructor:(

  ) ->

angular
  .module('mundoAdmin.home')
  .controller 'AdminHomeCtrl', AdminHomeCtrl
